import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FileModel } from "app/shared/models/general/general";
import { FileService } from "app/shared/services/General/upload-file.service";
import { MessagesService } from "app/shared/services/messages.service";

@Component({
  selector: "app-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.scss"],
})
export class FileInputComponent implements OnInit {
  @Input() control: FormControl;
  @Input() submitted: boolean = false;
  @Input() accept?: string;
  @Input() removeFile?: boolean;
  @Input() htmlId: string;
  @Input() label: string;

  constructor(private fileService: FileService, private msg: MessagesService) { }

  ngOnInit(): void { }
  ngOnChanges(): void {
    if (this.removeFile) {
      this.fileService.resetFileById(this.htmlId);
    }
  }

  async uploadFile(event) {
    await this.fileService.uploadFile(event).then((file: FileModel) => {
      // file to large > 10 .MB
      if (file.size > 10485760) {
        this.msg.warning("General.largFileError");
        if (this.htmlId) this.fileService.resetFileById(this.htmlId);
      } else {
        setTimeout(() => {
          this.control?.setValue(file);
        }, 100);
      }
    });
  }
}
