<ngx-spinner></ngx-spinner>
<div class="row">
  <div class="col-12">
    <mat-card class="example-card">
      <mat-card-title>{{ "labels.RefundLecture" | translate }}</mat-card-title>
      <mat-card-content>
        <form [formGroup]="myForm" class="form-container form-horizontal">
          <div class="form-group row">
            <div class="col-sm-3">
              <mat-label class="primary">{{
                "labels.Name" | translate
              }}</mat-label>
              <input
                disabled
                class="form-control bg-light-primary"
                readonly
                [value]="user?.ar_name"
              />
            </div>

            <div class="col-sm-3">
              <mat-label class="primary">{{
                "labels.Email" | translate
              }}</mat-label>
              <input
                disabled
                class="form-control bg-light-primary"
                readonly
                [value]="user?.account_email"
              />
            </div>

            <div class="col-sm-3">
              <mat-label class="primary">{{
                "labels.Mobile" | translate
              }}</mat-label>
              <input
                disabled
                class="form-control bg-light-primary"
                readonly
                [value]="user?.mobile"
              />
            </div>

            <div class="col-sm-3">
              <mat-label class="primary">{{
                "labels.Credits" | translate
              }}</mat-label>
              <input
                disabled
                class="form-control bg-light-primary"
                readonly
                [value]="user?.credits"
              />
            </div>
          </div>

          <div class="row f-w">
            <div class="col-md-4 col-12">
              <mat-form-field appearance="legacy">
                <mat-label>{{ "labels.Stage" | translate }} </mat-label>
                <mat-select
                  formControlName="stageId"
                  (selectionChange)="
                    getEduYearByStageId(FormCtrls.stageId.value)
                  "
                >
                  <mat-option> {{ "General.Choose" | translate }}</mat-option>
                  <mat-option
                    *ngFor="let stage of Stages"
                    [value]="stage.stage_id"
                  >
                    {{ stage.stage_name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="FormCtrls.stageId.hasError('required')">
                  {{ "labels.MustChoosevalue" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4 col-12">
              <mat-form-field appearance="legacy">
                <mat-label>{{ "labels.Year" | translate }}</mat-label>
                <mat-select
                  formControlName="yearId"
                  (selectionChange)="
                    getSubjectsByYearId(FormCtrls.yearId.value)
                  "
                >
                  <mat-option> {{ "General.Choose" | translate }}</mat-option>
                  <mat-option
                    *ngFor="let year of Years"
                    [value]="year.educational_year_id"
                  >
                    {{ year.educational_year_name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="FormCtrls.yearId.hasError('required')">
                  {{ "labels.MustChoosevalue" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4 col-12">
              <mat-form-field appearance="legacy">
                <mat-label>{{ "Material.Subject" | translate }}</mat-label>
                <mat-select
                  formControlName="subjectId"
                  (selectionChange)="
                    getTeachersBySubId(FormCtrls.subjectId.value)
                  "
                >
                  <mat-option> {{ "General.Choose" | translate }}</mat-option>
                  <mat-option
                    *ngFor="let subject of Subjects"
                    [value]="subject.Value"
                  >
                    {{ subject.Text }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="FormCtrls.subjectId.hasError('required')">
                  {{ "labels.MustChoosevalue" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4 col-12">
              <mat-form-field appearance="legacy">
                <mat-label>{{ "Material.Teacher" | translate }}</mat-label>
                <mat-select
                  formControlName="teacherId"
                  (selectionChange)="
                    getUserBoughtedMaterials(FormCtrls.teacherId.value)
                  "
                >
                  <mat-option> {{ "General.Choose" | translate }}</mat-option>
                  <mat-option
                    *ngFor="let teacher of Teachers"
                    [value]="teacher.Value"
                  >
                    {{ teacher.Text }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="FormCtrls.teacherId.hasError('required')">
                  {{ "labels.MustChoosevalue" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-4 col-12">
              <mat-form-field appearance="legacy">
                <mat-label>{{ "Material.Lecture" | translate }}</mat-label>
                <mat-select formControlName="RefundedLectureId">
                  <mat-option> {{ "General.Choose" | translate }}</mat-option>
                  <mat-option *ngFor="let lec of Lectures" [value]="lec.Value">
                    {{ lec.Text }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="FormCtrls.RefundedLectureId.hasError('required')"
                >
                  {{ "labels.MustChoosevalue" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions align="end">
        <button
          mat-raised-button
          class="btn btn-primary"
          (click)="refundLecture()"
        >
          {{ "General.Refund" | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
