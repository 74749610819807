<mat-form-field [appearance]="appearance">
  <mat-label>
    {{ label | translate }}
  </mat-label>
  <input
    matInput
    [matDatepicker]="date"
    [formControl]="control"
    [placeholder]="placeholder | translate"
    [max]="maxDate"
  />
  <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
  <mat-datepicker #date></mat-datepicker>
  <mat-hint>
    <input-validation-msg
      [control]="control"
      [submitted]="submitted"
      [isDate]="true"
    >
    </input-validation-msg>
  </mat-hint>
</mat-form-field>
