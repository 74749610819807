<ngx-spinner></ngx-spinner>
<div class="row">
	<div class="col-12">
		<mat-card>
			<mat-card-title>
				{{ "Navbar.helping-requests" | translate }}
				<button (click)="getAllRequests(1)" mat-raised-button class="btn bg-light-info float-right">
					{{ "General.Search" | translate }}
				</button>

				<button
					(click)="form.reset(); getAllRequests(1)"
					mat-raised-button
					class="btn bg-light-warning float-right"
				>
					مسح الحقول
				</button>
			</mat-card-title>
			<mat-card-content class="container-fluid">
				<div class="row">
					<div class="col-12">
						<form [formGroup]="form" class="form-container">
							<div class="row">
								<div class="col-md-4 col-12">
									<mat-form-field appearance="legacy">
										<mat-label> {{ "labels.requestId" | translate }} </mat-label>
										<input matInput type="text" formControlName="requestId" />
									</mat-form-field>
								</div>
							</div>
						</form>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>

<div class="row">
	<div class="col-12">
		<mat-card>
			<mat-card-content>
				<mat-form-field appearance="standard">
					<mat-label>{{ "General.Search" | translate }}</mat-label>
					<input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input />
				</mat-form-field>

				<div class="mat-elevation-z8 f-w">
					<table mat-table [dataSource]="dataSource" matSort>
						<!-- # Column -->
						<!-- <ng-container matColumnDef="#">
							<th mat-header-cell *matHeaderCellDef>#</th>
							<td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
						</ng-container> -->

						<!-- subject Column -->
						<!-- <ng-container matColumnDef="subject">
							<th mat-header-cell *matHeaderCellDef >
								{{ "labels.SubjectName" | translate }}
							</th>
							<td mat-cell *matCellDef="let ele">
								{{ languageService.isArabic ? ele.subject_ar_name : ele.subject_ar_name }}
							</td>
						</ng-container> -->

						<!-- requestId Column -->
						<ng-container matColumnDef="requestId">
							<th mat-header-cell *matHeaderCellDef class="text-center">
								{{ "labels.requestId" | translate }}
							</th>
							<td mat-cell *matCellDef="let ele">
								{{ ele.id }}
							</td>
						</ng-container>

						<!-- status Column -->
						<ng-container matColumnDef="status">
							<th mat-header-cell *matHeaderCellDef class="text-center">
								{{ "labels.Status" | translate }}
							</th>
							<td mat-cell *matCellDef="let ele">
								<span class="danger" *ngIf="ele.link == null">
									{{ "labels.Pendeing" | translate }}
								</span>
								<!-- currentTime < ele.DateOfZoomMeeting && -->
								<span class="info" *ngIf="ele.canJoin">
									{{ "labels.availableNow" | translate }}
								</span>

								<!-- 	currentTime >= ele.DateOfZoomMeeting && -->
								<span
									class="warning"
									*ngIf="ele.link != null && !ele.canJoin && !ele.isDone && authService.isStudent()"
								>
									سوف يتم إتاحة الرابط بعد قليل
								</span>

								<span
									class="warning"
									*ngIf="
										ele.link != null && !ele.canJoin && !ele.isDone && !authService.isStudent()
									"
								>
									سوف يتم عقد الاجنماع بعد قليل
								</span>

								<span class="success" *ngIf="ele.isDone">
									{{ "labels.meetingDone" | translate }}
								</span>
							</td>
						</ng-container>

						<!-- time Column -->
						<ng-container matColumnDef="time">
							<th mat-header-cell *matHeaderCellDef class="text-center">
								{{ "labels.Time" | translate }}
							</th>
							<td mat-cell *matCellDef="let ele">
								<span dir="ltr">
									{{ (ele.DateOfZoomMeeting | Time) ?? "لم يتم تحديد الوقت" }}
								</span>
							</td>
						</ng-container>

						<!-- actions Column -->
						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef class="text-center">
								{{ " actions" | translate }}
							</th>
							<td mat-cell *matCellDef="let ele">
								<!-- Teacher actions -->
								<ng-container *ngIf="!authService.isStudent()">
									<button class="btn btn-info round mr-1 mb-1" (click)="createMeetingLink(ele)">
										اضافه البيانات
									</button>

									<button
										class="btn btn-danger round mr-1 mb-1"
										(click)="confirmClose(ele)"
										*ngIf="ele.link != null && !ele.isDone && ele.canJoin"
									>
										إغلاق الطلب
									</button>
								</ng-container>

								<!-- Student actions -->
								<ng-container *ngIf="authService.isStudent()">
									<!-- display if zoom link added and meeting not start yet and currentTime is bigger than or equal  time of meeting  -->
									<!-- ele.link != null && !ele.isDone && currentTime >= ele.DateOfZoomMeeting; -->
									<button
										*ngIf="ele.canJoin; else noAction"
										class="btn btn-info round mr-1 mb-1"
										(click)="joinMeeting(ele.link)"
									>
										{{ "labels.Joinmeeting" | translate }}
									</button>
									<ng-template #noAction>--</ng-template>
								</ng-container>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns" class="text-center"></tr>

						<!-- Row shown when there is no matching data. -->
						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
						</tr>
					</table>

					<mat-paginator
						[pageSizeOptions]="[10]"
						(page)="getAllRequests($event.pageIndex + 1)"
						[length]="totalItems"
						[pageSize]="10"
						showFirstLastButtons
					>
					</mat-paginator>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>
