import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ICreateLinkRequests, IHelpingRequests } from "app/student/models/helping-requests";
import { HelpingRequestService } from "app/student/services/helping-request.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: "app-create-request-data",
	templateUrl: "./create-request-data.component.html",
	styleUrls: ["./create-request-data.component.scss"],
})
export class CreateRequestDataComponent implements OnInit {
	form: FormGroup;
	submitted: boolean = false;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IHelpingRequests,
		public dialogRef: MatDialogRef<CreateRequestDataComponent>,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private _helpingRequestService: HelpingRequestService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			RequestId: [0, [Validators.required]],
			DateOfZoomMeeting: [null, [Validators.required]],
			link: [null, [Validators.required]],
		});
		this.form.patchValue(this.data);
		this.FormCtrls.RequestId.setValue(this.data.id);
		this.FormCtrls.DateOfZoomMeeting.setValue(this.mapTime(this.data.DateOfZoomMeeting));
		console.log("data", this.data);
		console.log("FormCtrls", this.form.value);
	}

	get FormCtrls() {
		return this.form.controls;
	}

	submite() {
		this.submitted = true;
		if (this.form.valid) {
			this.spinner.show();
			let body: ICreateLinkRequests = {
				RequestId: this.FormCtrls.RequestId.value,
				DateOfZoomMeeting: this.FormCtrls.DateOfZoomMeeting.value,
				link: this.FormCtrls.link.value,
			};
			this._helpingRequestService.createMeetingLink(body).subscribe((res: any) => {
				if (res.returnValue == 1) {
					this.toastr.success("تم حفظ البيانات");
					this.dialogRef.close(true);
				}
				this.spinner.hide();
			});
		}
	}

	mapTime(time: Date) {
		if (time) {
			let _time = time.toString().split("T");
			let timeParts = _time[1].split(":");
			return `${timeParts[0]}:${timeParts[1]}`;
		}
	}
}
