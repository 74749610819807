<mat-form-field [appearance]="appearance" [class]="class">
  <mat-label> {{ label | translate }} </mat-label>
  <textarea
    matInput
    [formControl]="control"
    [placeholder]="placeholder | translate"
    [rows]="rows"
    [cols]="cols"
  >
  </textarea>
  <mat-hint>
    <input-validation-msg [control]="control" [submitted]="submitted">
    </input-validation-msg>
  </mat-hint>
</mat-form-field>
