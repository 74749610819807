import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
})
export class RadioButtonComponent {
  @Input() control: FormControl;
  @Input() label?: string;
  @Input() radioList: RadioButtonModel[];
  @Input() submitted: boolean = false;
  @Output() onChange? = new EventEmitter<any>();

  change(event: MatRadioChange) {
    this.onChange.emit(event.value);
  }
}

export interface RadioButtonModel {
  label: string;
  value: any;
  cssClass?: string;
}
