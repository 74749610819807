import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "whatsappVariables",
})
export class WhatsappVariablesPipe implements PipeTransform {
  transform(value: string) {
    return value.replace(/[{,}]/g, "");
  }
}
