<!--Footer Starts-->
<footer class="footer">
  <p
    class="copyright clearfix text-center text-muted m-0"
    style="direction: rtl"
  >
    <span class="d-none d-sm-inline-block">جميع الحقوق محفوظة </span>
    &copy; {{ currentDate | date : "yyyy" }}
    <a id="#dd" href="oxford.co">KW Oxford</a>
  </p>
</footer>
<!--Footer Ends-->
