<span
  class="text-danger"
  *ngIf="control.invalid && (control.touched || submitted)"
>
  <span
    *ngIf="
      (control.errors?.required || control.errors?.whiteSpace) &&
      !isSelect &&
      !isDate &&
      !isFile
    "
  >
    {{ "labels.mustEnterValue" | translate }}
  </span>

  <span *ngIf="control?.errors?.required && isSelect">
    {{ "labels.MustChoosevalue" | translate }}
  </span>

  <span *ngIf="control?.errors?.required && isFile" class="file-error">
    {{ "labels.MustSelectFile" | translate }}
  </span>

  <span *ngIf="control.errors?.required && isDate">
    {{ "labels.mustEnterDate" | translate }}
  </span>
  <span *ngIf="control.errors?.dateBefore && isDate">
    {{ "labels.toDatedIsBefore" | translate }}
  </span>

  <!-- <span *ngIf="control.errors?.minusNum || control.errors?.pattern">
    {{ "labels.mustEnterCorrectValue" | translate }}
  </span> -->

  <span *ngIf="control.errors?.pattern">
    {{ "labels.mustEnterCorrectValue" | translate }}
  </span>

  <span *ngIf="control.errors?.minusNum">
    {{ "labels.minusNum" | translate }}
  </span>

  <span *ngIf="control.errors?.email">
    {{ "labels.mustEnterValidEmail" | translate }}
  </span>

  <span *ngIf="control.errors?.bigZero">
    {{ "labels.bigZero" | translate }}
  </span>

  <span *ngIf="control.errors?.MobileStartsWith">
    {{ "labels.NumberMustStartWith" | translate }}
  </span>

  <span *ngIf="control.errors?.minlength">
    {{ "labels.minLength" | translate }}

    {{ control.errors?.minlength.requiredLength }}
  </span>

  <span *ngIf="control.errors?.maxlength">
    {{ "labels.maxLength" | translate }}

    {{ control.errors?.maxlength.requiredLength }}
  </span>

  <span *ngIf="control.errors?.max">
    {{ "labels.maxNum" | translate }}
    {{ control.errors?.max.max }}
  </span>

  <span *ngIf="control.errors?.min">
    {{ "labels.minNum" | translate }}
    {{ control.errors?.min.min }}
  </span>

  <span *ngIf="control.errors?.mustMatch">
    {{ "labels.notMatchPassword" | translate }}
  </span>

  <span *ngIf="control.errors?.smallerThanNumbers">
    {{ "labels.smallerThan" | translate }}
  </span>
</span>
