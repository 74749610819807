<div class="row">
  <div class="col-12">
    <mat-label>
      {{ label | translate }}
    </mat-label>
    <input
      [id]="htmlId"
      class="form-control"
      type="file"
      [accept]="accept"
      name="answerFourImage"
      (change)="uploadFile($event)"
    />
    <span class="danger">
      <input-validation-msg
        [control]="control"
        [submitted]="submitted"
        [isFile]="true"
      >
      </input-validation-msg>
    </span>
  </div>
</div>
