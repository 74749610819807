export const arStylesFiles: StyleModel[] = [
  {
    id: "style1",
    href: "assets/css-rtl/bootstrap.css",
    rel: "stylesheet",
  },
  {
    id: "style2",
    href: "assets/css-rtl/bootstrap-extended.css",
    rel: "stylesheet",
  },
  {
    id: "style3",
    href: "assets/css-rtl/colors.css",
    rel: "stylesheet",
  },
  {
    id: "style4",
    href: "assets/css-rtl/components.css",
    rel: "stylesheet",
  },
  {
    id: "style5",
    href: "assets/css-rtl/themes/layout-dark.css",
    rel: "stylesheet",
  },
  {
    id: "style6",
    href: "assets/css-rtl/plugins/switchery.css",
    rel: "stylesheet",
  },
  {
    id: "style7",
    href: "assets/css-rtl/custom-rtl.css",
    rel: "stylesheet",
  },
  {
    id: "style8",
    href: "assets/css-rtl/core/menu/horizontal-menu.css",
    rel: "stylesheet",
  },
  {
    id: "style9",
    href: "assets/css-rtl/pages/dashboard1.css",
    rel: "stylesheet",
  },
  {
    id: "style10",
    href: "assets/css-rtl/style-rtl.css",
    rel: "stylesheet",
  },
  {
    id: "ar_fonts",
    href: "assets/fonts/ar-fonts.css",
    rel: "stylesheet",
  },
];

export const enStylesFiles: StyleModel[] = [
  {
    id: "en_fonts",
    href: "assets/fonts/en-fonts.css",
    rel: "stylesheet",
  },
];

export interface StyleModel {
  id: string;
  href: string;
  rel: string;
}
