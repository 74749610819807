import { BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { FileModel } from "app/shared/models/general/general";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor() {}

  async uploadFile(event: any) {
    let file = event.target.files[0];
    var reader = new FileReader();
    let data = {} as FileModel;
    reader?.readAsDataURL(file);
    reader.onload = (event: any) => {
      data.FileAsBase64 = event.target.result;
    };
    data.name = file.name;
    data.size = file.size;
    data.type = file.type;
    data.LastModified = file.lastModified;
    data.LastModifiedDate = file.lastModifiedDate;
    return await data;
  }

  downloadFile(url: string) {
    window.open(environment.apiURL.replace("/api/", "") + url, "_blank");
  }

  previewPicture(url: any) {
    if (url) {
      if (!url.FileAsBase64)
        return environment.apiURL.replace("/api/", "") + url;
      else return url.FileAsBase64;
    }
  }

  resetFileInputs() {
    const FileInputs = document.querySelectorAll('input[type="file"]');
    FileInputs.forEach((input: any) => {
      input.value = null;
    });
  }

  resetFileById(htmlId: string) {
    console.log("htmlId", htmlId);
    if (htmlId != null)
      (<HTMLInputElement>document?.getElementById(htmlId)).value = null;
  }
}
