import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sliceAnnouncement",
})
export class SliceAnnouncementPipe implements PipeTransform {
  transform(value: any) {
    //console.log(value.replace(/(<([^>]+)>)/gi, ""));
    return value.replace(/(<([^>]+)>)/gi, "");
  }
}
