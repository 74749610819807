<mat-form-field appearance="legacy">
  <mat-label>{{ "Material.Subjects" | translate }}</mat-label>
  <mat-select
    [formControl]="subjectControl"
    [multiple]="multiple"
    (selectionChange)="onChange($event)"
  >
    <mat-option value="">
      {{ "Material.Subjects" | translate }}
    </mat-option>
    <mat-option *ngFor="let subject of subjects" [value]="subject.subject_id">
      {{ subject.subject_ar_name }}
    </mat-option>
  </mat-select>
  <mat-hint>
    <input-validation-msg
      [control]="subjectControl"
      [submitted]="submitted"
      [isSelect]="true"
    >
    </input-validation-msg>
  </mat-hint>
</mat-form-field>
