<h1 mat-dialog-title class="primary-color">
	{{ "labels.requestData" | translate }}
</h1>
<hr />
<div mat-dialog-content>
	<form [formGroup]="form">
		<div class="row">
			<div class="col-md-6 col-12">
				<app-input [control]="FormCtrls.link" [submitted]="submitted" label="labels.zoomLink">
				</app-input>
			</div>

			<div class="col-md-6 col-12">
				<app-input
					[control]="FormCtrls.DateOfZoomMeeting"
					[submitted]="submitted"
					type="time"
					label="labels.Time"
				>
				</app-input>
			</div>
		</div>
	</form>
</div>
<div mat-dialog-actions>
	<button mat-raised-button [mat-dialog-close]="false" class="btn btn-danger float-right">
		{{ "General.Close" | translate }}
	</button>
	<button (click)="submite()" mat-raised-button class="btn btn-primary float-right">
		{{ "General.Save" | translate }}
	</button>
</div>

<ngx-spinner></ngx-spinner>
