import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "app/shared/services/auth/auth.service";
import { LanguageService } from "app/shared/services/language.service";
import { IGetRequests, IHelpingRequests } from "app/student/models/helping-requests";
import { HelpingRequestService } from "app/student/services/helping-request.service";
import { CreateRequestDataComponent } from "../create-request-data/create-request-data.component";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { interval } from "rxjs";

@Component({
	selector: "app-helping-requests",
	templateUrl: "./helping-requests.component.html",
	styleUrls: ["./helping-requests.component.scss"],
})
export class HelpingRequestsComponent implements OnInit {
	displayedColumns: string[] = [
		//	"#",
		"requestId",
		// "subject",
		"time",
		"status",
		"actions",
	];
	dataSource: MatTableDataSource<IHelpingRequests>;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	totalItems: number;
	curr_page: number = 1;
	form: FormGroup;
	currentTime;
	constructor(
		private _helpingRequestService: HelpingRequestService,
		public authService: AuthService,
		private dialog: MatDialog,
		private spinner: NgxSpinnerService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		public languageService: LanguageService
	) {
		this.currentTime = datePipe.transform(new Date(), "yyyy-MM-ddThh:mm:ss");
		console.log(this.currentTime);
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			page: [this.curr_page, Validators.required],
			requestId: [null],
			teacherSubjectId: [null],
		});
		this.getAllRequests(this.curr_page);
	}

	ngAfterViewInit() {
		if (this.dataSource) {
			this.dataSource.paginator = this.paginator;
		}
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	getAllRequests(page: number) {
		let body: IGetRequests = {
			page: page,
			requestId: this.form.get("requestId").value,
			teacherSubjectId: this.form.get("teacherSubjectId").value,
		};
		this.spinner.show();
		this.curr_page = page;
		this._helpingRequestService
			.getAllRequests(body)
			.subscribe((res: { itemCounts: number; zoom_Requests: IHelpingRequests[] }) => {
				if (res) {
					console.log(res);
					this.totalItems = res.itemCounts;
					this.dataSource = new MatTableDataSource(res.zoom_Requests);
					res.zoom_Requests.map((req) => {
						if (
							req.DateOfZoomMeeting != null &&
							!req.isDone &&
							new Date(req.DateOfZoomMeeting).getTime() <= new Date().getTime()
						) {
							req.canJoin = true;
						} else {
							req.canJoin = false;
						}
					});
				}
				this.spinner.hide();
			});
	}

	closeZoomRequest(request: IHelpingRequests) {
		this.spinner.show();
		this._helpingRequestService.closeZoomRequest(request.id).subscribe((res: any) => {
			if (res) {
				this.getAllRequests(this.curr_page);
			}
			this.spinner.hide();
		});
	}

	confirmClose(request: IHelpingRequests) {
		this.dialog
			.open(ConfirmDialogComponent, {
				data: {
					btn: "General.Confirm",
					title: " إغلاق الطلب",
					msg: `هل انت متأكد من إغلاق الطلب رقم (${request.id})`,
				},
			})
			.afterClosed()
			.subscribe((confirm) => {
				if (confirm) this.closeZoomRequest(request);
			});
	}

	createMeetingLink(request: IHelpingRequests) {
		this.dialog
			.open(CreateRequestDataComponent, {
				data: request,
				width: "750px",
			})
			.afterClosed()
			.subscribe((confirm) => {
				if (confirm) this.getAllRequests(this.curr_page);
			});
	}

	joinMeeting(zoomLink: string) {
		window.open(
			//"https://zoom.us/wc/join" + id,
			zoomLink,
			"_blank",
			"location=yes,height=600,width=800,scrollbars=yes,status=yes"
		);
	}
}
