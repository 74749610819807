import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppStrings } from "app/shared/data/app-strings";
import { ZoomService } from "app/shared/services/zoom.service";
import { Subscription } from "rxjs";
import { ZoomMtg } from "@zoomus/websdk";
import { CustomeValidator } from "app/shared/validators/customeValid.validator";
import { GeneralService } from "app/shared/services/General/general.service";
import { IUserVM } from "app/shared/models/general/general";
import { NgxSpinnerService } from "ngx-spinner";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.13.0/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

@Component({
  selector: "app-zoom-meeting",
  templateUrl: "./zoom-meeting.component.html",
  styleUrls: ["./zoom-meeting.component.scss"],
})
export class ZoomMeetingComponent implements OnInit {
  // startMeetingForm!: FormGroup;
  subcription: Subscription;
  submitted: boolean = false;
  user: IUserVM;
  @Input() showInputs: boolean = false;
  @Input() meetingNumber: string;
  @Input() passWord: string;
  @Input() zoomSdkKey?: string;
  @Input() zoomSdkSecret?: string;

  constructor(
    private zoomService: ZoomService,
    private fb: FormBuilder,
    private router: Router,
    private appStrings: AppStrings,
    private GeneralServ: GeneralService,
    private spinner: NgxSpinnerService
  ) {
    this.subcription = new Subscription();
  }

  ngOnInit(): void {
    // this.startMeetingForm = this.fb.group({
    // 	// meeting data
    // 	meetingNumber: ["5928232148", [Validators.required, CustomeValidator.whiteSpace]],
    // 	passWord: ["FXZ7QB", [Validators.required, CustomeValidator.whiteSpace]],
    // 	// the name of the user joining the meeting
    // 	userName: ["" /* , [Validators.required, CustomeValidator.whiteSpace] */],
    // 	userEmail: [""],
    // });



    this.getUserInfo();
  }

  // get formCtrls() {
  // 	return this.startMeetingForm.controls;
  // }

  getUserInfo() {
    this.spinner.show();
    this.GeneralServ.returnProfile().subscribe((res: any) => {
      this.user = res.user;
      if (!this.showInputs) this.onSubmit();
      this.spinner.hide();
    });
  }

  onSubmit() {
    this.submitted = true;

    // if (this.startMeetingForm.valid) {
    // let formValues: any = this.startMeetingForm.value;
    let signaturePaylod: any = {
      meetingNumber: this.meetingNumber,
      apiKey: this.appStrings.zoomSdkKey,
      apiSecret: this.appStrings.zoomSdkSecret,
      role: "1",
    };
    this.subcription.add(
      this.zoomService
        .genrateSignature(signaturePaylod)
        .subscribe((signature: string) => {
          // console.log(signature, signaturePaylod);
          this.handleGenrateSignature(signature);
        })
    );
    // }
  }

  private handleGenrateSignature(signature: string) {
    let meetingPayloads: any = {
      meetingNumber: this.meetingNumber.replace(/\s/g, ""),
      passWord: this.passWord,
      signature: signature,
      userEmail: "",
      userName: this.user.ar_name,
    };
    document.getElementById("zmmtg-root").style.display = "block";
    this.zoomService.initializeWebSDK(meetingPayloads, this.router.url);
    // this.zoomService.generateMeetingUrl(signature).subscribe((res) => {
    //   console.log(res);
    // });
  }

  ngOnDestroy() {
    this.subcription.unsubscribe();
    // this.router.navigateByUrl("/student/zoom-meeting");
  }
}
