import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-appretiation',
  templateUrl: './my-appretiation.component.html',
  styleUrls: ['./my-appretiation.component.scss']
})
export class MyAppretiationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
