import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { StyleModel, arStylesFiles, enStylesFiles } from "../data/styles-path";
import { AppStrings } from "../data/app-strings";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  currentLang: string;
  isArabic: boolean;
  isEnglish: boolean;
  arStyles: StyleModel[];
  enStyles: StyleModel[];

  constructor(
    private translate: TranslateService,
    private appStrings: AppStrings
  ) {
    this.arStyles = arStylesFiles;
    this.enStyles = enStylesFiles;
    this.currentLang = localStorage.getItem(this.appStrings.CURRENT_LANG);
  }

  changeLanguage(language: string) {
    this.translate.use(language ?? this.appStrings.DEFAULT_LANG);
    localStorage.setItem(
      this.appStrings.CURRENT_LANG,
      language ?? this.appStrings.DEFAULT_LANG
    );
    this.currentLang = language;
    // this.translate.onLangChange.subscribe((lang) => {
    if (this.currentLang == this.appStrings.ar) {
      console.log("isArabic");
      this.isArabic = true;
      this.isEnglish = false;
    } else {
      this.isEnglish = true;
      this.isArabic = false;
    }
    //  });
    // console.log("lang", language);
    // console.log("isEnglish", this.isEnglish);
    // console.log("isArabic", this.isArabic);
    this.changeStyle(language);
  }

  changeStyle(currentLang) {
    const head = document.getElementsByTagName("head")[0];
    if (currentLang == this.appStrings.ar) {
      // add ar style
      this.arStyles.forEach((style) => {
        let ele = document.createElement("link");
        ele.id = style.id;
        ele.rel = style.rel;
        ele.href = style.href;
        head.appendChild(ele);
      });

      // remove en style
      this.enStyles.forEach((style) => {
        document.getElementById(style.id)?.remove();
      });
    } else {
      // add en style
      this.enStyles.forEach((style) => {
        let ele = document.createElement("link");
        ele.id = style.id;
        ele.rel = style.rel;
        ele.href = style.href;
        head.appendChild(ele);
      });

      // remove ar style
      this.arStyles.forEach((style) => {
        document.getElementById(style.id)?.remove();
      });
    }
  }
}
