import { NgxPaginationModule } from "ngx-pagination";
import { NgxSpinnerModule } from "ngx-spinner";
import { SaveExamDialogComponent } from "./components/dialogs/save-exam-dialog/save-exam-dialog.component";
import { MaterialModule } from "app/shared/material/material.module";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from "@angular/cdk/overlay";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from "ng-click-outside";

import { AutocompleteModule } from "./components/autocomplete/autocomplete.module";
import { PipeModule } from "app/shared/pipes/pipe.module";

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from "./customizer/customizer.component";
import { NotificationSidebarComponent } from "./notification-sidebar/notification-sidebar.component";

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from "./directives/sidebar-link.directive";
import { SidebarDropdownDirective } from "./directives/sidebar-dropdown.directive";
import { SidebarAnchorToggleDirective } from "./directives/sidebar-anchor-toggle.directive";
import { SidebarDirective } from "./directives/sidebar.directive";
import { TopMenuDirective } from "./directives/topmenu.directive";
import { TopMenuLinkDirective } from "./directives/topmenu-link.directive";
import { TopMenuDropdownDirective } from "./directives/topmenu-dropdown.directive";
import { TopMenuAnchorToggleDirective } from "./directives/topmenu-anchor-toggle.directive";
import { GeneralEvaluationComponent } from "./components/evaluations/general-evaluation/general-evaluation.component";
import { ChartistModule } from "ng-chartist";
import { MyAppretiationComponent } from "./components/my-appretiation/my-appretiation.component";
import { MaterailsGeneralEvaluationComponent } from "./components/evaluations/materails-general-evaluation/materails-general-evaluation.component";
import { LeaderBoardComponent } from "./components/leader-board/leader-board.component";
import { HorizontalMenuComponent } from "./horizontal-menu/horizontal-menu.component";
import { EnterCodeDialogComponent } from "./components/dialogs/enter-code-dialog/enter-code-dialog.component";
import { DeleteDialogComponent } from "./components/dialogs/delete-dialog/delete-dialog.component";
import { ConfirmDialogComponent } from "./components/dialogs/confirm-dialog/confirm-dialog.component";

import { RefundLectureComponent } from "./components/refund-lecture/refund-lecture.component";
import { UserBalanceDetailsComponent } from "./components/user-balance-details/user-balance-details.component";
import { SubjectEvaluationComponent } from "./components/evaluations/subject-evaluation/subject-evaluation.component";
import { UserDataComponent } from "./components/user-data/user-data.component";
import { DatePipe } from "@angular/common";
import { ButtonFileInputUploadComponent } from "./components/dynamic-form-field/button-file-input-upload/file-input-upload.component";
import { DatepickerInputComponent } from "./components/dynamic-form-field/datepicker-input/datepicker-input.component";
import { FileInputComponent } from "./components/dynamic-form-field/file-input/file-input.component";
import { InputComponent } from "./components/dynamic-form-field/input/input.component";
import { InputValidationMsgComponent } from "./components/dynamic-form-field/input-validation-msg/input-validation-msg.component";
import { RadioButtonComponent } from "./components/dynamic-form-field/radio-button/radio-button.component";
import { TextareaComponent } from "./components/dynamic-form-field/textarea/textarea.component";
import { SubjectsByTeacheridComponent } from "./components/dropdowns/subjects-by-teacherid/subjects-by-teacherid.component";
import { ZoomMeetingComponent } from "./components/zoom-meeting/zoom-meeting.component";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { HelpingRequestsComponent } from "./components/zoom-helping-requests/helping-requests/helping-requests.component";
import { CreateRequestDataComponent } from './components/zoom-helping-requests/create-request-data/create-request-data.component';

@NgModule({
	providers: [DatePipe],
	imports: [
		RouterModule,
		CommonModule,
		NgbModule,
		TranslateModule,
		FormsModule,
		OverlayModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		ClickOutsideModule,
		AutocompleteModule,
		PipeModule,
		ChartistModule,
		NgApexchartsModule,
		MaterialModule,
		NgxSpinnerModule,
		NgxPaginationModule,
		SweetAlert2Module.forRoot(),
	],
	declarations: [
		FooterComponent,
		NavbarComponent,
		VerticalMenuComponent,
		HorizontalMenuComponent,
		CustomizerComponent,
		NotificationSidebarComponent,
		ToggleFullscreenDirective,
		SidebarLinkDirective,
		SidebarDropdownDirective,
		SidebarAnchorToggleDirective,
		SidebarDirective,
		TopMenuLinkDirective,
		TopMenuDropdownDirective,
		TopMenuAnchorToggleDirective,
		TopMenuDirective,
		GeneralEvaluationComponent,
		MyAppretiationComponent,
		MaterailsGeneralEvaluationComponent,
		LeaderBoardComponent,
		EnterCodeDialogComponent,
		DeleteDialogComponent,
		ConfirmDialogComponent,
		SaveExamDialogComponent,
		RefundLectureComponent,
		UserBalanceDetailsComponent,
		SubjectEvaluationComponent,
		UserDataComponent,
		ButtonFileInputUploadComponent,
		DatepickerInputComponent,
		FileInputComponent,
		InputComponent,
		InputValidationMsgComponent,
		RadioButtonComponent,
		TextareaComponent,
		SubjectsByTeacheridComponent,
		ZoomMeetingComponent,
		HelpingRequestsComponent,
  CreateRequestDataComponent,
	],

	exports: [
		CommonModule,
		FooterComponent,
		NavbarComponent,
		VerticalMenuComponent,
		HorizontalMenuComponent,
		CustomizerComponent,
		NotificationSidebarComponent,
		ToggleFullscreenDirective,
		SidebarDirective,
		TopMenuDirective,
		NgbModule,
		TranslateModule,
		PipeModule,
		GeneralEvaluationComponent,
		MyAppretiationComponent,
		MaterailsGeneralEvaluationComponent,
		LeaderBoardComponent,
		EnterCodeDialogComponent,
		DeleteDialogComponent,
		ConfirmDialogComponent,
		SaveExamDialogComponent,
		RefundLectureComponent,
		FooterComponent,
		NavbarComponent,
		VerticalMenuComponent,
		HorizontalMenuComponent,
		CustomizerComponent,
		NotificationSidebarComponent,
		ToggleFullscreenDirective,
		SidebarLinkDirective,
		SidebarDropdownDirective,
		SidebarAnchorToggleDirective,
		SidebarDirective,
		TopMenuLinkDirective,
		TopMenuDropdownDirective,
		TopMenuAnchorToggleDirective,
		GeneralEvaluationComponent,
		MyAppretiationComponent,
		MaterailsGeneralEvaluationComponent,
		LeaderBoardComponent,
		DeleteDialogComponent,
		ConfirmDialogComponent,
		SaveExamDialogComponent,
		RefundLectureComponent,
		UserBalanceDetailsComponent,
		SubjectEvaluationComponent,
		UserDataComponent,
		ButtonFileInputUploadComponent,
		DatepickerInputComponent,
		FileInputComponent,
		InputComponent,
		InputValidationMsgComponent,
		RadioButtonComponent,
		TextareaComponent,
		SubjectsByTeacheridComponent,
		ZoomMeetingComponent,
		HelpingRequestsComponent,
	],
})
export class SharedModule {}
