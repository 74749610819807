import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.scss"],
})
export class TextareaComponent {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() type: string = "text";
  @Input() placeholder: string;
  @Input() rows: number;
  @Input() cols: number;
  @Input() submitted: boolean = false;
  @Input() appearance: string = "legacy";
  @Input() class?: string;
}
