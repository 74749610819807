import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-datepicker-input",
  templateUrl: "./datepicker-input.component.html",
  styleUrls: ["./datepicker-input.component.scss"],
})
export class DatepickerInputComponent {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() submitted: boolean = false;
  @Input() appearance: string = "legacy";
  @Input() maxDate: Date = null;

  constructor(private datePipe: DatePipe) {
    this.control?.setValue(datePipe.transform(this.control?.value));
  }
}
