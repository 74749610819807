import { VimeoLinkPipe } from "./vimeoLink.pipe";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FilterPipe } from "./filter.pipe";
import { SearchPipe } from "./search.pipe";
import { ShortNamePipe } from "./short-name.pipe";
import { WhatsappVariablesPipe } from "./whatsapp-variables.pipe";
import { NewDatePipe } from "./NewDate.pipe";
import { RoundPipe } from "./round.pipe";
import { SafePipe } from "./safe.pipe";
import { SecondsToTimePipe } from "./secondsToTime.pipe";
import { SliceAnnouncementPipe } from "./slice-announcement.pipe";
import { SliceExamPicPipe } from "./slice-exam-pic.pipe";
import { TimePipe } from "./Time.pipe";
import { TimerPipe } from "./Timer.pipe";

@NgModule({
  declarations: [
    FilterPipe,
    SearchPipe,
    ShortNamePipe,
    WhatsappVariablesPipe,
    RoundPipe,
    TimerPipe,
    TimePipe,
    SafePipe,
    NewDatePipe,
    SecondsToTimePipe,
    SliceExamPicPipe,
    SliceAnnouncementPipe,
    //VimeoLinkPipe,
  ],
  imports: [CommonModule],
  exports: [
    FilterPipe,
    SearchPipe,
    ShortNamePipe,
    WhatsappVariablesPipe,
    RoundPipe,
    TimerPipe,
    TimePipe,
    SafePipe,
    NewDatePipe,
    SecondsToTimePipe,
    SliceExamPicPipe,
    SliceAnnouncementPipe,
    //VimeoLinkPipe,
  ],
})
export class PipeModule {}
