import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { ICreateLinkRequests, IGetRequests, IGetStudentRequests } from "../models/helping-requests";

@Injectable({
	providedIn: "root",
})
export class HelpingRequestService {
	constructor(private http: HttpClient) {}

	contactRequest(teacherSubjectId: number) {
		return this.http.get(`${environment.apiURL}zoom/createZoomRequest/${teacherSubjectId}`);
	}

	getAllRequests(body: IGetRequests) {
		return this.http.post(`${environment.apiURL}zoom/getZoomRequest`, body);
	}

	createMeetingLink(body: ICreateLinkRequests) {
		return this.http.post(`${environment.apiURL}zoom/zoomRequestResponse`, body);
	}

	closeZoomRequest(requestId?: number) {
		return this.http.get(`${environment.apiURL}zoom/closeZoomRequest/${requestId}`);
	}
  getAllStudentRequests(body: IGetStudentRequests) {
		return this.http.post(`${environment.apiURL}zoom/getAllZoomRequests`, body);
	}
}
