import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppStrings {
  constructor() {}
  arabic: string = "عربي";
  english: string = "English";
  ar: string = "ar";
  en: string = "en";
  DEFAULT_LANG = "ar";
  CURRENT_LANG: string = "currentLang";
  TOKEN: string = "token";
  USER_TYPE_ID: string = "user_type_Id";
  YEAR_ID: string = "yearId";
  EDU_COMP_LIST: string = "EduCompList";
  USER_ID: string = "userId";
  CLASS_ID: string = "classId";
  BRANCH_ID: string = "branchId";
  EDUCOMP_ID: string = "EduCompId";
  CURRENT_PARENT_CHILD_ID: string = "currentParentChildId";
  zoomSdkKey: string = "cMSig6jnR3m5KyLoc7NEw"; // client id
  zoomSdkSecret: string = "vDDA2W9XQo8GVMxgTZUiq4RTd1EYYV6f"; // client secret
}
