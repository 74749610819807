import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ZoomMtg } from "@zoomus/websdk";
import { AppStrings } from "app/shared/data/app-strings";
import { IZoomDataModel } from "app/student/models/ask-teacher";
import { Observable, of } from "rxjs";
import { environment } from 'environments/environment';


@Injectable({
  providedIn: "root",
})
export class ZoomService {
  constructor(private appStrings: AppStrings, private http: HttpClient) {}

  initializeWebSDK(zoomClient: any, returnUrl: string): void {
    // ZoomMtg.preLoadWasm();
    // ZoomMtg.prepareWebSDK();
    // ZoomMtg.i18n.load('en-US');
    // ZoomMtg.i18n.reload('en-US');

    ZoomMtg.init({
      leaveUrl: returnUrl,
      // isSupportAV: true,
      success: (success: any) => {
        console.log(success);

        ZoomMtg.join({
          signature: zoomClient.signature,
          meetingNumber: zoomClient.meetingNumber,
          userName: zoomClient.userName,
          sdkKey: this.appStrings.zoomSdkKey,
          userEmail: zoomClient.userEmail,
          passWord: zoomClient.passWord,
          // tk: zoomClient.registrantToken,
          // zak: zoomClient.zakToken,
          success: (success: any) => {
            console.log(success);
            // console.log("dd", success.result.join_url);
          },
          error: (error: any) => {
            console.log(error);
          },
        });
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  genrateSignature(signatureModel: any): Observable<string> {
    let signature = ZoomMtg.generateSDKSignature({
      meetingNumber: signatureModel.meetingNumber,
      sdkKey: signatureModel.apiKey,
      sdkSecret: signatureModel.apiSecret,
      role: signatureModel.role,
    });
    return of(signature);
  }

  // generateMeetingUrl(signature: string): Observable<any> {
  //   const body = {
  //     topic: "Meeting Title",
  //     type: 1, // 1 for instant meetings, 2 for scheduled meetings, etc.
  //   };

  //   // console.log(signature);
  //   const options = {
  //     headers: {
  //       Authorization: "Bearer " + signature,
  //     },
  //   };

  //   return this.http.post(
  //     `https://api.zoom.us/v2.13.0/users/me/meetings`,
  //     body,
  //     options
  //   );
  // }

  getZoomDataForTeacher(teacherSubjectId: number) {
    return this.http.get<IZoomDataModel>(`${environment.apiURL }/zoom/getZoomDetails/${teacherSubjectId}`);
  }
}
