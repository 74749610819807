import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class AppAssets {
	constructor() {}
	arFlag: string = "assets/img/flags/ar.png";
	enFlag: string = "assets/img/flags/us.png";
	logo: string = "assets/img/logo-w.png";
	darkLogo: string = "assets/img/logo-dark.png";
	lock: string = "assets/img/gallery/lock.png";
	maintenance: string = "assets/img/gallery/maintenance.png";
	examCardBackground: string = "assets/img/lectureBackground.jpeg";
	examCardBackground2: string = "assets/img/ect.jpg";
	coursesNotFound: string = "assets/img/gallery/lock.png";
	static userAvatar: string = "assets/img/user.png";
}
