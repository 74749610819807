import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { subjectsDropdownVM } from "app/admin/models/admin/educations";
import { GeneralService } from "app/shared/services/General/general.service";
import { ITeacherSubjectsListVM } from "app/student/models/student";

@Component({
  selector: "app-subjects-by-teacherid",
  templateUrl: "./subjects-by-teacherid.component.html",
  styleUrls: ["./subjects-by-teacherid.component.scss"],
})
export class SubjectsByTeacheridComponent implements OnInit {
  @Input() subjectControl: FormControl;
  @Input() teacherIdControl: FormControl;
  @Input() submitted: boolean;
  @Input() multiple: boolean = false;
  @Output() selectionChange? = new EventEmitter<any>();

  subjects: ITeacherSubjectsListVM[];

  constructor(private GeneralService: GeneralService) {}

  ngOnInit(): void {
    this.subjectControl.disable();
    if (this.teacherIdControl.value > 0) {
      this.getSubjectByTeacherId(this.teacherIdControl.value);
      this.subjectControl.enable();
    }

    this.teacherIdControl.valueChanges.subscribe((teacherId) => {
      // this.subjectControl.setValue(null);
      if (teacherId > 0) {
        this.getSubjectByTeacherId(teacherId);
        this.subjectControl.enable();
      } else {
        this.subjectControl.setValue(null);
        this.subjectControl.disable();
      }
    });

    this.subjectControl.valueChanges.subscribe((subjectID) => {});
  }

  onChange(event: MatSelectChange) {
    this.selectionChange.emit(event.value);
  }

  getSubjectByTeacherId(teacherId: number) {
    this.GeneralService.getSubjectByTeacherId(teacherId, 36).subscribe(
      (res: any) => {
        this.subjects = res.subjects;
      }
    );
  }
}
