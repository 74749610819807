<mat-card class="card">
  <mat-card-title>My Appretiation</mat-card-title>
  <mat-card-content>
    <div class="mt pr-10 scrolled350">
      <div class="media pt-0 pb-2 mt-20">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-20.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Jessica Rice</h4>
          <p class="grey font-small-3 m-0">UX Designer</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-15.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Jacob Rios</h4>
          <p class="grey font-small-3 m-0">HTML Developer</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-7.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Russell Delgado</h4>
          <p class="grey font-small-3 m-0">Database Designer</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-6.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Sara McDonald</h4>
          <p class="grey font-small-3 m-0">Team Leader</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-16.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Janet Lucas</h4>
          <p class="grey font-small-3 m-0">Project Manger</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-25.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Mark Carter</h4>
          <p class="grey font-small-3 m-0">HTML Developer</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-6.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Sara McDonald</h4>
          <p class="grey font-small-3 m-0">Team Leader</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-16.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Janet Lucas</h4>
          <p class="grey font-small-3 m-0">Project Manger</p>
        </div>
      </div>
      <div class="media py-2">
        <img
          class="mr-3 avatar rounded"
          src="assets/img/portrait/small/avatar-s-25.png"
          alt="Avatar"
          width="35"
        />
        <div class="media-body">
          <h4 class="font-medium-1 mb-0">Mark Carter</h4>
          <p class="grey font-small-3 m-0">HTML Developer</p>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
