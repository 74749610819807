<div class="row">
  <div class="col-12">
    <button
      type="button"
      class=" btn btn-info bg-light-info btn-sm float-right"
      mat-raised-button
      (click)="fileInput.click()"
    >
    <i class="fa fa-upload"></i>
    {{ "General.Upload" | translate }}
    </button>
    <input
      hidden
      #fileInput
      [id]="htmlId"
      class="form-control"
      type="file"
      [accept]="accept"
      name="answerFourImage"
      (change)="uploadFile($event)"
    />
    <span class="danger">
      <input-validation-msg
        [control]="control"
        [submitted]="submitted"
        [isFile]="true"
      >
      </input-validation-msg>
    </span>
  </div>
</div>
