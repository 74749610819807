import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { BehaviorSubject, Subject } from "rxjs";

declare const $: any;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  EduCompId = new Subject<any>();
  branchId = new Subject<any>();
  NewReservation = new Subject<any>();
  //EducationTypeId = new Subject<any>();
  CurrentReservation;

  headers = new HttpHeaders().append("Content-Type", "application/json");
  constructor(private http: HttpClient, private router: Router) {}

  // START Registeration
  Registeration<T, TE>(data: T) {
    return this.http.post<TE>(environment.apiURL + "APIUsers/Register", data);
  }

  // START LOGIN
  login<T, TE>(loginData: T) {
    return this.http.post<TE>(environment.apiURL + "user/login", loginData);
  }

  //START LOGOUT
  logout() {
    Object.keys(localStorage).forEach((key) => {
      if (!(key.includes("currentLang") || key.includes("NewReservation")))
        delete localStorage[key];
    });
    //   localStorage.clear();
    this.router.navigateByUrl("/home");
  }

  // START Forgot Password
  forgotPassword(email: any) {
    return this.http.post(
      environment.apiURL + "APIUsers/forgotPassword",
      email
    );
  }

  // START Reset Password
  resetPassword(data: any) {
    return this.http.post(
      environment.apiURL + "APIUsers/resetPasswordByVerificationCode",
      data
    );
  }

  getUserFunctions(branchId: any) {
    return this.http.get(
      `${environment.apiURL}usermanagement/return-functions/${branchId}`
    );
  }

  getUserRowFunctions(parentId: any) {
    return this.http.get(
      `${environment.apiURL}usermanagement/return-children-functions-by-parentId/${parentId}`
    );
  }

  //================= Start  Chick Users ==================
  isUser() {
    return !!localStorage.getItem("token");
  }

  isTeacher() {
    if (localStorage.getItem("user_type_Id") == "1" && this.isUser()) {
      return true;
    } else return false;
  }

  isStudent() {
    if (localStorage.getItem("user_type_Id") == "2" && this.isUser()) {
      return true;
    } else return false;
  }

  isParent() {
    if (localStorage.getItem("user_type_Id") == "3" && this.isUser()) {
      return true;
    } else return false;
  }

  isAdmin() {
    if (localStorage.getItem("user_type_Id") == "4" && this.isUser()) {
      return true;
    } else return false;
  }

  isCenter() {
    if (localStorage.getItem("user_type_Id") == "5" && this.isUser()) {
      return true;
    } else return false;
  }

  isSupport() {
    if (localStorage.getItem("user_type_Id") == "6" && this.isUser()) {
      return true;
    } else return false;
  }

  isFinancial() {
    if (localStorage.getItem("user_type_Id") == "7" && this.isUser()) {
      return true;
    } else return false;
  }

  isAssistant() {
    if (localStorage.getItem("user_type_Id") == "8" && this.isUser()) {
      return true;
    } else return false;
  }

  // isUser GetToken
  getToken() {
    return localStorage.getItem("token") || null;
  }

  getNewReservation(): number {
    return Number(localStorage.getItem("NewReservation"));
  }

  getEduCompId(): number {
    return Number(localStorage.getItem("EduCompId"));
  }

  getBranchId(): number {
    return Number(localStorage.getItem("branchId"));
  }

  setEduCompId(EduCompId) {
    localStorage.setItem("EduCompId", EduCompId || null);
    this.EduCompId.next({ EduCompId });
  }

  setBranchId(branchId) {
    localStorage.setItem("branchId", branchId || null);
    this.branchId.next({ branchId });
  }

  setNewReservation(NewReservation: any) {
    console.log("Auth:", NewReservation);
    localStorage.setItem("NewReservation", NewReservation || 0);

    this.NewReservation.subscribe((valu) => {
      this.CurrentReservation = valu ?? 0;
    });
    if (this.CurrentReservation != NewReservation)
      this.NewReservation.next(NewReservation);
  }

  // setEducationType(EducationTypeId: any) {
  //   console.log("EducationTypeId:", EducationTypeId);
  //   localStorage.setItem("EducationTypeId", EducationTypeId);
  //   this.EducationTypeId.next(EducationTypeId);
  // }

  //Current YearId
  CurrentYearId(): number {
    return Number(localStorage.getItem("yearId"));
  }

  //Current StageId
  CurrentStageId(): number {
    return Number(localStorage.getItem("stageId"));
  }
} //end class
