<mat-label>
  {{ label | translate }}
</mat-label>

<mat-radio-group [formControl]="control" class="row">
  <div
    *ngFor="let radio of radioList"
    [class]="radio.cssClass ?? 'col-md-6 col-12'"
  >
    <!-- (change)="change($event)" -->
    <mat-radio-button [value]="radio.value" (change)="change($event)">
      {{ radio.label | translate }}
    </mat-radio-button>
  </div>
</mat-radio-group>

<mat-hint class="error">
  <input-validation-msg
    [control]="control"
    [submitted]="submitted"
    [isSelect]="true"
  >
  </input-validation-msg>
</mat-hint>
