import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "input-validation-msg",
  templateUrl: "./input-validation-msg.component.html",
  styleUrls: ["./input-validation-msg.component.scss"],
})
export class InputValidationMsgComponent {
  @Input() control: FormControl;
  @Input() submitted: boolean = false;
  @Input() isSelect?: boolean = false;
  @Input() isDate?: boolean = false;
  @Input() isFile?: boolean = false;

}
