import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() type: string = "text";
  @Input() placeholder: string;
  @Input() submitted: boolean = false;
  @Input() appearance: string = "legacy";
  @Input() class?: string;
  @Input() autocomplete?: string;
}

//Using
// <app-input
//   [control]="FormCtrls.FromGrade"
//   [submitted]="submitted"
//   label="whatsApp.FromGrade"
//   type="number"
// >
// </app-input>
