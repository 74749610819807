<h1 mat-dialog-title class="primary-color">
  {{ data.title | translate }}
</h1>
<div mat-dialog-content>
  <p>{{ data.msg | translate }}</p>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    [mat-dialog-close]="false"
    class="btn btn-danger float-right"
  >
    {{ "General.NoThanks" | translate }}
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="btn btn-primary float-right"
  >
    {{ data.btn | translate }}
  </button>
</div>
