<ngx-spinner></ngx-spinner>

<div class="row" id="zooomn">
  <div class="col-12" *ngIf="showInputs">
    <mat-card>
      <mat-card-title>
        {{ "zoom.JoinYourClass" | translate }}
      </mat-card-title>
      <!-- <mat-card-content class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form [formGroup]="startMeetingForm" class="form-container">
              <div class="row">
                <div class="col-md-6 col-12">
                  <app-input
                    [control]="formCtrls.meetingNumber"
                    [submitted]="submitted"
                    label="zoom.MeetingNumber"
                  ></app-input>
                </div>

                <div class="col-md-6 col-12">
                  <app-input
                    [control]="formCtrls.passWord"
                    [submitted]="submitted"
                    label="zoom.MeetingpassWord"
                  ></app-input>
                </div>

                <div class="col-md-6 col-12">
                  <app-input
                    [control]="formCtrls.userName"
                    [submitted]="submitted"
                    label="labels.Name"
                  ></app-input>
                </div>

                <div class="col-md-6 col-12">
                  <app-input
                    [control]="formCtrls.userEmail"
                    [submitted]="submitted"
                    label="labels.Email"
                  ></app-input>
                </div>
              </div>
            </form>
          </div>
        </div>
      </mat-card-content> -->

      <mat-card-actions align="end">
        <button
          #addBtn
          mat-raised-button
          class="btn btn-info"
          (click)="onSubmit()"
        >
          {{ "zoom.JoinMeeting" | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <!-- <div
    class="w-100 d-flex align-items-center justify-content-center p-2"
    *ngIf="!showInputs"
  >
    <button #addBtn mat-raised-button class="btn btn-info" (click)="onSubmit()">
      {{ "zoom.JoinMeeting" | translate }}
    </button>
  </div> -->
</div>
