import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  NewReservation: any = 0;
  constructor(private injector: Injector, private AuthService: AuthService) {
    this.NewReservation = localStorage.getItem("NewReservation");
    // console.log("Interceptor-NewReservation:", this.NewReservation);
    AuthService.NewReservation.subscribe((value) => {
      if (this.NewReservation != value) this.NewReservation = value;
      console.log("NewReservation", this.NewReservation);
    });
  }

  // INTERCEPTORS are not dependable classes so we need to use
  // Injector to hold the required dependencies
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authService = this.injector.get(AuthService);

    const tokenReq = req.clone({
      setHeaders: {
        Authorization: `Basic ${authService.getToken()}`,
        "New-Reservation": `${this.NewReservation}`,
        "Content-Type": "application/json",
      },
    });

    return next.handle(tokenReq);
  }
}
