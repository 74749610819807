import { TranslateService } from "@ngx-translate/core";
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  success(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    let msg;
    this.translate.get(message).subscribe((data) => {
      msg = this.toastr.success(data, title, override);
    });
    return msg;
  }

  error(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    let msg;
    this.translate.get(message).subscribe((data) => {
      msg = this.toastr.error(data, title, override);
    });
    return msg;
  }

  info(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    let msg;
    this.translate.get(message).subscribe((data) => {
      msg = this.toastr.info(data, title, override);
    });
    return msg;
  }

  warning(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    let msg;
    this.translate.get(message).subscribe((data) => {
      msg = this.toastr.warning(data, title, override);
    });
    return msg;
  }

  show(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ): ActiveToast<any> {
    let msg;
    this.translate.get(message).subscribe((data) => {
      msg = this.toastr.show(data, title, override);
    });
    return msg;
  }
}
